import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'

import auth from './auth'

export async function getData() {
  let users;
  if (location.port) {
    // Debug mode!
    const Response = await fetch("/users.json")
    users = await Response.json()
  } else {
    const user = await auth()
    const storage = firebase.storage();
    const downloadUrl = await storage.ref('users.json').getDownloadURL()
    const response = await fetch(downloadUrl)
    users = await response.json()
    if (!Array.isArray(users)) {
      console.log("data from storage was not an array. are you sure it's JSON-Simple format?", users)
      // fallback to get users from the firebase database
      const db = firebase.database()
      const snap = await db.ref('/users').once('value')
      users = snap.val()
    }
  }
  users = fixData(users)
  localStorage.setItem('users', JSON.stringify(users))
  return users
}

export function getLocalStorageData() {
  const users = localStorage.getItem('users')
  if (users && users != "{}") {
    return fixData(JSON.parse(users))
  }
}

function fixData(users) {
  // sometimes data has looker_profiles_VALUE in it others looker_profiles.VALUE
  // we want the period one.

  if (users.length > 0) {
    const keys = Object.keys(users[0])
    if (keys.length > 0) {
      if (keys[0].indexOf('.') == -1) {
        console.log('updating looker_profiles_VALUE to looker_profiles.VALUE', users[0])
        // no period found in keys
        users = users.map((user) => Object.entries(user).reduce(
          (acc, value) => {
            acc[value[0].replace('looker_profiles_', 'looker_profiles.')] = value[1];
            return acc
          },
          {}))
      }
    }
  }

  return users
}
