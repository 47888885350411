import React from 'react'
import clone from 'lodash.clone'

class ButtonToggle extends React.Component {
  constructor (props) {
    super(props)
  }

  render() {
    return (
        <tr><th>
	{this.props.title}:</th>
       <td> <button onClick={() => this.props.updateFilter(this.props.title)} className={!Object.keys(this.props.values).length ? "enabled" : ""}>ALL {this.props.title}</button>
      {
          (this.props.options || []).map((dep)=> (
              <button onClick={() => this.props.updateFilter(this.props.title, dep, !this.props.values[dep])} className={this.props.values[dep] ? "enabled" : ""}  key={dep}>{dep}</button>
          )
        }
          </td>
	  </tr>


      )
  }
}

export class PeopleFilter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      filters: {
        department: {}
        location: {}
      }
    }
  }

  // TODO support shift and ctrl and click
  updateFilter = (filterType, value, isOn) => {
    filterType = filterType.toLowerCase().slice(0, -1)
    let newState = clone(this.state)

    newState.filters[filterType] = {}
    if (value && isOn) {
      newState.filters[filterType][value] = true
    }

    this.setState(newState)
    this.props.updateFilter({
      location: Object.keys(newState.filters.location),
      department: Object.keys(newState.filters.department),
    })
  }


  searchChanged (e) {
    this.props.updateFilter({search: e.target.value})
  }

  render() {
    return (
      <div className="filter-menu">
        <h1>Who@Looker</h1>
	<table><tbody>
	  <tr>
	    <th>Search:</th>
	    <td> <input className="search"
                  autoFocus
                  type="search"
                  defaultValue={this.props.value.search}
      onChange={(e) => this.searchChanged(e)}
		  placeholder="type to search by name..." />
	    </td>
          </tr>
        <ButtonToggle title="Departments" options={this.props.departments} values={this.state.filters.department} updateFilter={this.updateFilter} />
        <ButtonToggle title="Locations" options={this.props.locations} values={this.state.filters.location} updateFilter={this.updateFilter} />

	<tr>
	  <th>Sort:</th>
          <td><button className={this.props.value.sort ? "enabled" : ""} onClick={() => {
	  this.props.updateFilter({sort: !this.props.value.sort})
	}}>Sort: Oldest First</button></td>
	</tr>
	</tbody></table>


      </div>
    )
  }
}
