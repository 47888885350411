import React from 'react'

import {Person} from './person'

export class People extends React.Component {

  render() {
    return (
    <div>
        <div className="lookeraterer"><a target="_self" href="/_/lookeraterer/">Looking for the Lookeraterer? Click Here...</a></div>

        <div className="people">
        {this.props.users.map((user) => (
            <Person key={user['looker_profiles.username']} user={user} />
        )}
      </div>
      </div>
    );
  }
}
