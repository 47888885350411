import firebase from 'firebase/app'
import 'firebase/auth'

import {config} from './config'

export default function auth () {
  console.log("Running auth")

  return new Promise((resolve, reject) => {
    firebase.initializeApp(config)

    function doLoginFlow () {
      console.log("doLoginFlow")
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().signInWithRedirect(provider).then(function(result) {
	resolve(result.user)
      }).catch(function(error) {
	reject(error)
      })
    }

    function getGoogleUser () {
      firebase.auth().getRedirectResult().then(function(result) {
	const user = result.user
	if (user) {
	  resolve(user)
	} else {
	  doLoginFlow()
	}
      }).catch(function(error) {
	doLoginFlow()
      })
    }

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
	resolve(user)
      } else {
	getGoogleUser()
      }
    })
  })

}
