import React from 'react'
import LazyLoad from 'react-lazyload'

export class Person extends React.Component {

  render() {
    const name = this.props.user['looker_profiles.name']
    const username = this.props.user['looker_profiles.username']
    const department = this.props.user['looker_profiles.department']
    const team = this.props.user['looker_profiles.team']

    return (
      <a  href={`https://lou.looker.com/lookers/${username}`}>
    <div className="person">
      <LazyLoad height={300} once={true} offset={400}>
        <img className="person-image" src={`https://lou.looker.com/assets/img/profile_picture/${username}.jpg`} />
      </LazyLoad>
      <div className="info">
        <div className="name">
            {name}
        </div>


        <div className="teams">
          <div>{department}</div>
          <div>{team}</div>
        </div>
      </div>
    </div>
    </a>
    );
  }
}
