import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import clone from 'lodash.clone'

import * as LazyLoad from 'react-lazyload'
import {People} from './people'
import {PeopleFilter} from './filter'

import {getData, getLocalStorageData} from './storage'

export class AllUsers extends React.Component {
  constructor (props) {
    super(props)
    this.state = {users: [], filters: {}}
  }

  componentDidUpdate (prevProps) {
    LazyLoad.forceCheck()
  }

  componentDidMount () {
    let that = this

    function handleUsers(users) {
      let departments = Object.keys(users.reduce((deps, user) => {
        const dep = user['looker_profiles.team']
        if (dep) {
          deps[dep] = true
        }
        return deps
      }, {})).sort()

      let locations = Object.keys(users.reduce((locs, user) => {
        "location,location_2".split(",").forEach((key) => {
          const loc = user[`looker_profiles.${key}`]
          if (loc) {
            locs[loc] = true
          }
        })
        return locs
      }, {})).sort().reverse()

      let allUsers = users

      let filters = clone(that.state.filters)
      const search = (that.props.match.params.search || "").toLowerCase()
      users = allUsers.filter((user) => {
        if (user['looker_profiles.name'].toLowerCase().indexOf(search) != -1 ||
            user['looker_profiles.username'].toLowerCase().indexOf(search) != -1) {
          return true
        }
      })

      if (that.props.match.params.search) {
	filters.search = that.props.match.params.search
      }
      users = that.updateUsers(filters, allUsers)

      that.setState({
        allUsers,
        departments,
        filters,
        locations,
        users,
      })
    }

    const users = getLocalStorageData()
    if (users) {
      handleUsers(users)
    }

    async function GetUsers() {
      const users = await getData()
      handleUsers(users)
    }

    GetUsers()
  }

  updateUsers (filters, allUsers) {

    let search = (filters.search || "").toLowerCase()

    let newUsers = allUsers.filter((user)=> {
      if (filters.department && filters.department.length) {
        if (filters.department.indexOf(user["looker_profiles.team"]) == -1) {
          return false
        }
      }

      if (filters.location && filters.location.length) {
        if (filters.location.indexOf(user["looker_profiles.location"]) == -1) {
          return false
        }
      }

      if (search) {
        if (user['looker_profiles.name'].toLowerCase().indexOf(search) == -1 &&
            user['looker_profiles.username'].toLowerCase().indexOf(search) == -1) {
          return false
        }
      }

      return true
    })

    newUsers.sort((a, b)=> {
      return a['looker_profiles.date_started_date'].localeCompare(b['looker_profiles.date_started_date']) * (filters.sort ? 1: -1)   ||
	a['looker_profiles.name'].localeCompare(b['looker_profiles.name'])
    })

    this.setState({users: newUsers})
    return newUsers
  }

  updateFilter = (filters) => {
    const newFilters = Object.assign(clone(this.state.filters), filters)
    this.setState({filters: newFilters})
    this.updateUsers(newFilters, this.state.allUsers)
  }

  render() {
    return (
        <div className="App">
        <PeopleFilter updateFilter={this.updateFilter} value={this.state.filters} departments={this.state.departments} locations={this.state.locations}/>
          <People users={this.state.users}/>
        </div>
    )
  }


export class App extends React.Component {
  constructor () {
    super()
  }

  render() {
    return (
      <BrowserRouter
      >
        <Switch>
        <Route path='/:search' component={AllUsers} />
        <Route path='/' component={AllUsers} />
</Switch>
      </BrowserRouter>
    )
  }
}
